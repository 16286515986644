<template>
  <div>
    <GmapMap
      :center="{ lat: 39.9281641, lng: 32.911237 }"
      :zoom="12"
      style="width: 100%; height: 400px"
    >
    </GmapMap>

    <button @click="openMaps" class="btn btn-primary mt-3">Haritada aç</button>
  </div>
</template>

<script>
export default {
  methods: {
    openMaps() {
      const lat = 39.9281641;
      const lng = 32.911237;

      // Open Apple Maps on iOS devices
      if (
        navigator.platform.indexOf("iPhone") !== -1 ||
        navigator.platform.indexOf("iPad") !== -1 ||
        navigator.platform.indexOf("iPod") !== -1
      ) {
        window.open(`maps://maps.apple.com/?q=${lat},${lng}`);
      } else {
        // Open Google Maps on other devices
        window.open(`https://www.google.com/maps?q=${lat},${lng}`);
      }
    },
  },
};
</script>
