<template>
  <footer>
    <div class="container small border-top">
      <div class="row py-5 d-flex justify-content-between">
        <div class="col-12 col-lg-6 col-xl-8 border-end p-5">
          <GMap />
          <address class="text-secondary mt-3">
            Tıp Fakültesi Caddesi<br />
            No: 238 Mamak, Ankara, 06470<br />
            <abbr title="Phone">Tel:</abbr>
            +90 (536) 368 38 47
          </address>
        </div>
        <div class="col-12 col-lg-6 col-xl-4 border-end p-5">
          <h3 class="h6 mb-3">Sayfalar</h3>
          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link link-secondary ps-0" href="#hakkimda">
                Özgeçmiş
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link link-secondary ps-0"
                href="#degerli-mamaklilar"
                aria-label="Brings you to the frontpage"
              >
                Değerli Mamaklılar
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-secondary ps-0" href="#projects">
                Projeler
              </a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link link-secondary ps-0" href="#numbers"> Rakamlarla Mamak </a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link link-secondary ps-0" href="#contactMe">
                İletişim
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container text-center py-3 small">
      <a href="https://sefaaltioglu.com" class="link-fancy" target="_blank"
        >sefaaltioglu.com | 2024</a
      >
    </div>
  </footer>
</template>

<script>
import GMap from "./GMap.vue";

export default {
  components: {
    GMap,
  },
};
</script>
