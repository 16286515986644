<template>
  <nav
    id="navScroll"
    class="navbar navbar-expand-lg navbar-light fixed-top"
    tabindex="0"
  >
    <div class="container">
      <a
        class="navbar-brand pe-4 fs-5"
        href="/"
        style="display: flex; align-items: center"
      >
        <img
          src="img/chp.png"
          class="rounded img-fluid mr-2"
          width="50"
          height="50"
        />
        <div style="flex-direction: column; display: inline-flex">
          <span class="ms-1 courgette-regular-font sefa">Sefa</span>
          <span class="ms-1 courgette-regular-font altioglu">Altıoğlu</span>
        </div>
      </a>
      <template>
        <b-navbar-toggle
          target="navbarSupportedContent"
          toggler="collapse"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </b-navbar-toggle>

        <b-collapse id="navbarSupportedContent" is-nav>
          <b-navbar-nav class="me-auto" style="gap: 10px">
            <a class="nav-item nav-link" href="#hakkimda"> Özgeçmiş </a>
            <a
              class="nav-item nav-link"
              href="#degerli-mamaklilar"
              aria-label="Brings you to the frontpage"
            >
              Değerli Mamaklılar
            </a>
            <a class="nav-item nav-link" href="#projects"> Projeler </a>
            <!-- <a class="nav-item nav-link" href="#numbers"> Rakamlarla Mamak </a> -->
            <a class="nav-item nav-link" href="#contactMe"> İletişim </a>
          </b-navbar-nav>
        </b-collapse>
      </template>
    </div>
  </nav>
</template>

<script>
import AOS from "aos";

export default {
  mounted() {
    AOS.init({
      duration: 800, // values from 0 to 3000, with step 50ms
    });

    let scrollpos = window.scrollY;
    const header = document.querySelector(".navbar");
    const header_height = header.offsetHeight;

    const add_class_on_scroll = () =>
      header.classList.add("scrolled", "shadow-sm");
    const remove_class_on_scroll = () =>
      header.classList.remove("scrolled", "shadow-sm");

    window.addEventListener("scroll", function () {
      scrollpos = window.scrollY;

      if (scrollpos >= header_height) {
        add_class_on_scroll();
      } else {
        remove_class_on_scroll();
      }
    });
  },
  methods: {
    downloadPDF() {
      const pdfPath = "/sefa-altioglu-basvuru.pdf"; // Update the path accordingly
      const downloadLink = document.createElement("a");

      downloadLink.href = pdfPath;
      downloadLink.download = "sefa-altioglu-basvuru.pdf";
      downloadLink.click();
    },
  },
};
</script>

<style scoped>
.sefa {
  color: #e3060f;
}
.altioglu {
  color: #0f395a;
}
</style>
