<template>
  <div
    class="py-vh-4 bg-gray-100 text-light w-100 overflow-hidden"
    id="canli-yayin"
  >
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="row d-flex justify-content-center text-center">
          <div class="col-12 py-5">
            <h3 class="mb-3" data-aos="fade-left" style="color: #0f395a">
              Canlı Yayın
            </h3>
            <audio
              :src="audioSrc"
              controls
              style="width: 100%"
              data-aos="fade-left"
            ></audio>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      audioSrc: "/mp3/canli-yayin-kayit.mp3",
    };
  },
};
</script>

<style>
/* Change the color of the audio controls' background */
audio::-webkit-media-controls-panel {
  background-color: #0f3a5a55; /* Blue color */
}
</style>
