import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/theme.min.css";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCjJNrrHWR_nBg_Z7f7wfGmNcWuB-e_d0w",
    libraries: "places", // You can add other libraries as needed
  },
});

Vue.use(BootstrapVue);

import AudioVisual from "vue-audio-visual";

Vue.use(AudioVisual);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
