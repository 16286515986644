<template>
  <div
    class="py-vh-6 text-light w-100 overflow-hidden"
    style="background: #0f395a;"
    id="contactMe"
  >
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="row d-flex justify-content-center text-center">
          <div class="col-12">
            <a
              class="btn btn-xl shadow me-3 mt-4"
              style="background: #e3060f; color: white"
              data-aos="fade-down"
              @click="openMailApp"
              >Benimle iletişime geçin</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openMailApp() {
      var emailAddress = "iletisim@sefaaltioglu.com";
      var subject = "";
      var body = "";

      var mailtoLink =
        "mailto:" +
        emailAddress +
        "?subject=" +
        encodeURIComponent(subject) +
        "&body=" +
        encodeURIComponent(body);

      // Open the default mail application
      window.location.href = mailtoLink;
    },
  },
};
</script>
