<template>
  <div class="container py-vh-4 w-100 overflow-hidden">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-5"></div>
      <div class="col-md-7" data-aos="fade-left">
        <blockquote>
          <div class="fs-4 my-3 fw-light pt-4 border-bottom pb-3">
            “MAMAK GÜNEŞ'İN ŞEHRİ OLACAK.”
          </div>

          <SignAndPhoto />
        </blockquote>
      </div>
    </div>
  </div>
</template>

<script>
import SignAndPhoto from "./SignAndPhoto";

export default {
  components: {
    SignAndPhoto,
  },
};
</script>
