<template>
  <body data-bs-spy="scroll" data-bs-target="#navScroll">
    <NavBar />

    <main>
      <TopComponent />
      <CanliYayin />
      <AboutMeComponent />
      <!-- <AboutMeComponent2 /> -->
      <DegerliMamaklilarComponent />
      <ProjectsComponent />
      <!-- <NumbersComponent /> -->
      <!-- <GalleryComponent /> -->
      <WhatOurClientsSayComponent />
      <ContactMeComponent />
      <!-- <TestimonialsComponent /> -->
      <!-- <BottomServicesComponent /> -->
      <!-- <StayInTouchComponent /> -->
    </main>

    <FooterComponent />
  </body>
</template>

<script>
import CanliYayin from "../components/CanliYayin";
import NavBar from "../components/NavBar";
import FooterComponent from "../components/FooterComponent";
import TopComponent from "../components/TopComponent";
import ProjectsComponent from "../components/ProjectsComponent";
import AboutMeComponent from "../components/AboutMeComponent";
// import AboutMeComponent2 from "../components/AboutMeComponent2";
import DegerliMamaklilarComponent from "../components/DegerliMamaklilarComponent";
// import NumbersComponent from "../components/NumbersComponent";
// import GalleryComponent from "../components/GalleryComponent";
import ContactMeComponent from "../components/ContactMeComponent";
// import TestimonialsComponent from "../components/TestimonialsComponent";
// import BottomServicesComponent from "../components/BottomServicesComponent";
// import StayInTouchComponent from "../components/StayInTouchComponent";
import WhatOurClientsSayComponent from "../components/WhatOurClientsSayComponent";

export default {
  components: {
    CanliYayin,
    NavBar,
    FooterComponent,
    TopComponent,
    ProjectsComponent,
    AboutMeComponent,
    // AboutMeComponent2,
    DegerliMamaklilarComponent,
    // NumbersComponent,
    // GalleryComponent,
    ContactMeComponent,
    // TestimonialsComponent,
    // BottomServicesComponent,
    // StayInTouchComponent,
    WhatOurClientsSayComponent,
  },
};
</script>
