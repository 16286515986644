<template>
  <div
    class="py-vh-4 bg-gray-100 w-100 overflow-hidden"
    id="degerli-mamaklilar"
  >
    <div class="container">
      <div class="row d-flex justify-content-between align-items-center">
        <div class="col-lg-8">
          <h3 class="py-4 border-top border-dark" data-aos="fade-left">
            Sevgili Mamaklılar, sevgili hemşehrilerim
          </h3>
          <p data-aos="fade-left" data-aos-delay="200">
            <span>
              Yerel yöneticilerinin vizyonu ne kadar genişse o yerelin yaşayanlarının ufkunun da o kadar, hatta daha fazla genişleyeceğine inanıyorum. Yerleşik kalıpların, standart uygulamaların yarattığı rehavet içindeki yönetim anlayışlarının insanlara dokunamadığını, onların mutsuzluklarına, umutsuzluklarına çare olmadığını sınaya sınaya hala öğrenemediğimiz bir zamandayız. 
<br>Umutsuzlaştırıldığı için yarınını göremeyen, çaresizleştirilen insanlarımızın bireysel olarak da, toplumsal olarak da ihtiyacı; mevcut dinamiklerine özgüvenle sahip çıkarak onları harekete geçirme sorumluluğunu kabul edecek bir yapının parçası olmaktır.   
<br>Tam da bu nedenle içine doğduğum, içinde büyüdüğüm, değerlerini de yoksulluk ve yoksunluğunu da gördüğüm; insanlarını ayırımsız sevdiğim; yaşamıma, kişiliğime ve bugünüme büyük katkısı olan Mamak’a ve insanlarına karşı hissettiğim manevi borcumu yerine getirme şansını elde edebilmek için Cumhuriyet Halk Partisi Mamak Belediye Başkanlığı adaylığı için başvuruda bulundum.
<br>Ankara Büyükşehir Belediyesinde yaklaşık 6 yıl Avukat, 12-13 yıl da Hukuk İşleri Daire Başkanı olarak görev yapmış olmanın doğal sonucu olarak Belediye Encümeni ve çeşitli komisyon üyelikleri kapsamında, Belediyenin yaptığı tüm uygulamaların içinde oldum. Bu çerçevede, bir Belediye tarafından yürütülen her çeşit iş ve mevzuata hâkim olmanın birikimini, içinde büyüdüğüm Mamak ilçesine ve insanlarına daha iyi bir Belediyecilik ile dokunabilmek, hayatlarını güzelleştirmek, özellikle gençlere ve kadınlara yönelik sosyal politika ve projelerle Mamak’ın ve insanlarının önünü açarak onlarla paylaşmak istiyorum.
<br>Şehrin kalbinde yer alıp bir kenar mahalle muamelesi gören Mamak’ı, kaynaklarını etkin kullanan, tüm gerçekçi ve verimli iş birliklerine açık, büyük yatırımların bileşeni olan bir cazibe merkezi haline getirmek için atılacak her adım Mamak’lının da hem ekonomik, hem de sosyo-kültürel gelişmesi ve mutluluğu demek olacaktır. 
<br>Yönetimi tarafından teşvik edilerek üreten, ürettiği için kendine güvenen, haklarının sonuna kadar korunacağından kuşku duymayacak kadınları ve erkekleri; gününden ve geleceğinden endişesi olmadan mutlu büyüyecek çocukları; ilgilerine ve yatkınlıklarına göre mesleki eğitimlerle donanarak, ücretsiz internet ağları ile dünyayla iletişim halinde yaratıcılıkları gelişerek hayata hazırlanan gençleri; hayatın kendilerine getirdiği dezavantajlar yüzünden hayatın pratiklerinde yer almaktan çekinmeyecek ve kendilerine özgü geliştirilen olanaklardan yararlanarak toplumuna katkı verecek ve karşılığını alacak insanları; yaşının ilerlemesinin getireceği olası sağlık problemlerinin hemen yakınında çözülebileceğini görecek büyükleri ile Mamak, mutlu ve kalplerindeki güneşin hiç batmadığı insanlarıyla örnek haline gelecektir. 
<br><br>
Yaşamlarını, duygularını, ihtiyaçlarını çok yakından bildiğim tüm Mamaklıları ayırımsız kucaklayacak bir anlayışla, onları özledikleri yerel yönetime kavuşturmak için ve bir düşünürün ifadesiyle “Öyle davran ki, davranışların genel kural haline gelsin” düsturu ile yönetmek üzere Mamak Belediye Başkanlığına adayım.
            </span>
          </p>

        </div>

        <div class="col-lg-8">
          <p class="pb-4" data-aos="fade-left" data-aos-delay="200">
            <span>
              <span class="font-weight-bold"> Kısacası; </span>
              <br />
              Yılların belediyecilik birikimiyle Mamak'ta hep birlikte mutlu
              olup mutlu yaşamak, doğru işler yapmak için aday adayı oldum.
              <br />
              Güzel bir söz vardır:
              <span class="fst-italic"
                >"Başarmak için biraz da o işe layık olmak gerekir."</span
              >
              <br />
              Ankara Büyükşehir Belediyesi'nde uzun yıllar üst düzey görevlerde
              bulundum, hukuk müşavirliği yaptım. Belediyeye ve belediye
              birikimlerine dair işleyişini, kanunları iyi bilmekteyim.
              <br />
              Belediye başkanı olduğumda göreve başladığım anda hizmete hemen
              başlayabilecek bilgi ve tecrübeye sahibim. Yıllarımı belediyeyi
              tanımakla geçirmeyeceğim.
              <br />
              Belediye başkanı olduğumda başarılı olacağıma inanıyor, bu göreve
              layık olduğumu düşünüyorum.
            </span>
          </p>
        </div>
      </div>

      <div
        class="row d-flex justify-content-between align-items-center"
        data-aos="fade-left"
        data-aos-delay="200"
      >
        <div class="col-lg-8">
          <h5 class="pt-2 pb-2">SEÇİMİ KAZANMAK İDDİAM VAR</h5>
          <p>
            Mamak'ta seçimi kazanabilmemiz için değişik parti tabanlarından,
            siyasete uzak duranlardan, siyasi küskünlerden destek almamız
            gerekiyor. Seçmenlerin adayın tecrübesine güvenmesi, ona inanması
            gerekiyor. İnsanlarla yıllarca kurmuş olduğum sosyal ilişkilerimden
            ve belediyede uzun yıllar çalışmamdan kaynaklı oluşan gönül bağından
            dolayı, bu kesimlerden seçimi kazanmak içingereken oyu olacağımı
            düşünüyorum. Bu konuda kendime güveniyorum.
          </p>
          <p>
            <span class="font-weight-bold"> Sevgili Mamaklılar, </span>
            <br />
            <span class="font-weight-bold"
              >Değişime, Mamak'tan başlayacağız.
            </span>
            <br />
            <span class="font-weight-bold"> GÜNEŞİN DOĞDUĞU YERDEN. </span>
            <br />
            <br />
            <span
              >Mamak’ın yokluğunu, yoksulluğunu, ezilmişliğini bilirim. Mamak'ın
              tozlu, çamurlu yollarında yürüdüm. Dolmuş duraklarında, otobüs
              duraklarında, tren istasyonlarında çok bekledim, üşüdüm.
            </span>
            <br />
            <br />
            Seçimi kazanıp, liyakatlı, ahlaklı, tüm insanları kucaklayan,
            öteleştirmeyen, kamu terbiyesi almış, alçakgönüllü kadrolarımızla
            Mamaklı hemşerilerimize hizmet edeceğiz.
            <br />
            <br />
            Saygılarımla…
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bulletPoints: [
        "Mamak'ın kentsel gelişimini, dönüşümünü sağlamak için,",
        "Güneş yüzlü insanların yaşadığı bir ilçe yapmak için,",
        "Şehir yaşamının bunaltıcı etkisinden insanlarımızı biraz olsun kurtaracak projeler üretmek için,",
        "Güzel yaşam alanları sunmak için,",
        "Kent meydanları yapmak için,",
        "Sanatsal etkinliklerin yapıldığı (Tiyatroların oynandığı, bağlamanın, gitarın, kemanın, kavalın, davulun sesinin duyulduğu) salonlar, mekanlar yapmak için,",
        "Mamak'ı Ankara’nın sanat merkezi yapmak için,",
        "Dar gelirli ailelere uygun koşullu “konut projeleri“ üretmek, onların barınma sorununu biraz olsun gidermek için,",
        "Gençlerimize spor yapmalarını sağlayacak spor merkezleri-alanları yapmak için,",
        "Gençlerimizin üniversite yaşamlarını kolaylaştırmak için, Mamak'a mutlaka bir üniversite kurulmasını sağlamak için,",
        "Çocukları, gençleri, yaşlıları seven projeler üretmek için,",
        "Insanı, doğayı ve yaşamı seven projeler yapmak için,",
      ],
    };
  },
};
</script>
