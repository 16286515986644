<template>
  <div class="py-vh-5 w-100 overflow-hidden" id="projects">
    <div class="container">
      <div class="row d-flex justify-content-end">
        <div class="col-lg-12" data-aos="fade-down">
          <h3>
            Mamak Belediye Başkanlığı Aday Adayı Olarak Düşündüğüm Projeler
          </h3>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div
          v-for="(project, index) in projects"
          :key="index"
          :class="getClassname(index)"
          data-aos="fade-up"
          :data-aos-delay="getDelay(index)"
        >
          <span class="h5 fw-lighter">{{ index + 1 }}.</span>
          <h3 class="py-4 border-top border-dark">
            {{ project.projectTitle }}
          </h3>
          <p>{{ project.projectDescription }}</p>
          <!-- <a href="#" class="link-fancy">Daha fazlasını okuyun </a> -->
        </div>
      </div>

      <!-- <SignAndPhoto class="pt-5 float-right" /> -->
    </div>
  </div>
</template>

<script>
// import SignAndPhoto from "./SignAndPhoto";

export default {
  components: {
    // SignAndPhoto,
  },
  data() {
    return {
      projects: [
        {
          projectTitle: "Çöplükten Enerji Üretimi",
          projectDescription:
            "Belediyede çalıştığım dönemlerde birçok yabancı firma, çöplükten enerji üretmek adına girişimlerde bulunmuşlardı. Büyükşehir belediyesi ile anlaşarak, Mamak'ın enerji ihtiyacını buradan karşılamak en öncelikli hedefimdir.",
        },
        {
          projectTitle: "Üniversite Kurulumu",
          projectDescription:
            "Mamak'a mutlaka bir üniversiteyi 5 yıl içerisinde kazandıracağım.",
        },
        {
          projectTitle: "Altyapı ve Üstyapı Geliştirmeleri",
          projectDescription:
            "Nüfusun bu denli yoğun olduğu Mamak'ta itfaiye, otel, avm ve benzeri gerekli yapıları en kısa sürede programlı bir şekilde ilçemize kazandıracağım.",
        },
        {
          projectTitle: "Kentsel Planlama ve Konut Üretimi",
          projectDescription:
            "Doğukent'te yaklaşık 40.000 konutluk nazım, uygulama, parselasyon ve imar planları var. Bunları en kısa sürede faaliyete geçirip Mamak'lıya uygun, güvenli konutlar üreteceğim. Bu bölgelerde kentleşmeyi olması gerektiği gibi planlı bir şekilde yürüteceğim.",
        },
        {
          projectTitle: "Yaşlılar İçin Sosyal Alanlar",
          projectDescription:
            "Yaşlılara uygun dizayn edilmiş yaşlılar parkları, yürüyüş parkları, yürüyüş yolları ve sosyal aktivite alanları yapacağım.",
        },
        {
          projectTitle: "Gençlerimize Spor ve Sosyal Yaşam Olanakları",
          projectDescription:
            "Gençlerimize ilgilerine ve yatkınlıklarına göre sporun her alanında kendilerini geliştirmelerine, yapacağımız spor merkezleriyle olanak tanıyacağız.",
        },
      ],
    };
  },
  methods: {
    getClassname(index) {
      if (index % 3 === 0) {
        return "col-md-6 col-lg-4";
      } else if (index % 3 === 1) {
        return "col-md-6 col-lg-4 py-vh-4 pb-0";
      } else if (index % 3 === 2) {
        return "col-md-6 col-lg-4 py-vh-6 pb-0";
      }

      return "col-md-6 col-lg-4";
    },
    getDelay(index) {
      if (index % 3 === 0) {
        return 200;
      } else if (index % 3 === 1) {
        return 400;
      } else if (index % 3 === 2) {
        return 600;
      }

      return 200;
    },
  },
};
</script>
